<template>
  <div>
    <p v-if="statusInfo.request === 'DRAFT'">Tu solicitud de crédito pasará por <b>3 estados</b> hasta que puedas recibir el dinero.</p>
    <v-stepper flat class="mt-4 mb-10">
      <v-stepper-header>
        <v-stepper-step :complete="firstStepComplete" complete-icon="" step="" />
        <v-divider></v-divider>
        <v-stepper-step :complete="secondStepComplete" complete-icon="" step="" />
        <v-divider></v-divider>
        <v-stepper-step :complete="thirdStepComplete" complete-icon="" step="" />
      </v-stepper-header>
    </v-stepper>

    <div class="text-center">
      <p class="mb-0">El estado actual de tu crédito es</p>
      <p class="text-h5 font-weight-bold">{{ contentByCreditStatus.status }}</p>
      <p>{{ contentByCreditStatus.text }}</p>
      <v-img :src="require(`@/assets/images/${contentByCreditStatus.img}.svg`)" class="mt-10" contain height="250" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statusInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    firstStepComplete() {
      const validRequestStatus = ["DRAFT", "REVIEW", "REJECTED", "APPROVED"];
      return validRequestStatus.includes(this.statusInfo.request);
    },
    secondStepComplete() {
      const validRequestStatus = ["REJECTED", "APPROVED"];
      return validRequestStatus.includes(this.statusInfo.request);
    },
    thirdStepComplete() {
      const validRequestStatus = ["APPROVED"];
      const validProjectStatus = ["SENT"];
      return validRequestStatus.includes(this.statusInfo.request) && validProjectStatus.includes(this.projectStatus);
    },
    contentByCreditStatus() {
      switch (this.statusInfo.request) {
        case "DRAFT":
        case "REVIEW":
          return {
            status: "PROCESANDO",
            text: "Aún estamos valorando tu solicitud. No te preocupes, seguro que todo va a ir genial.",
            img: "step1"
          };
        case "APPROVED":
          switch (this.statusInfo.project) {
            case "APPROVED":
            case "FUNDED":
            case "INVESTABLE":
              return {
                status: "APROBADO",
                text: "Hemos aprobado tu crédito, sin embargo aún falta algo de tiempo para que puedas disponer del dinero. Te notificaremos cuando puedas pasar a recoger.",
                img: "step2"
              };
            case "SENT":
              return {
                status: "DINERO LISTO PARA RECOGER",
                text: "Puedes pasar a recoger tu dinero en la oficina MICROWD que elegiste al crear tu solicitud. Si tienes dudas puedes llamar a uno de nuestros técnicos en el 654 132 456.",
                img: "step3"
              };

            default:
              return null;
          }
        case "REJECTED":
          return {
            status: "EN ESPERA",
            text: "Necesitamos algo más de información para valorar tu solicitud. Un asesor se pondrá en contacto contigo en los próximos días.",
            img: "step2"
          };

        default:
          return null;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-stepper__step__step {
  width: 35px;
  height: 35px;
}
</style>
