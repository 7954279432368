<template>
  <v-container fill-height fluid style="max-width: 600px">
    <v-row align="center" justify="center">
      <v-col cols="12" class="mb-4">
        <div v-if="creditHistory.length > 0">
          <RequestStatus v-if="statusInfo" :statusInfo="statusInfo" />

          <div v-else class="text-center">
            <p class="text-h5 font-weight-bold">Actualmente no tiene ningún proceso en curso con MICROWD</p>
            <p>
              Si desea crear una nueva solicitud haga click en "Salir" y, en la pantalla anterior, haga click en el botón "Empezar nueva
              solicitud"
            </p>
          </div>
        </div>

        <div v-else class="text-center">
          <p class="text-h5 font-weight-bold">¡Solicite su primer crédito!</p>
          <p class="text-h6 my-10">
            Para ello haga click en "Salir" y, en la pantalla anterior, haga click en el botón "Empezar nueva solicitud"
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import RequestStatus from "@/components/request-info/RequestStatus.vue";

export default {
  name: "BorrowerRequests",
  components: {
    RequestStatus
  },
  computed: {
    ...mapState("borrower", ["creditHistory", "borrower_data"]),
    statusInfo() {
      const requests = this.creditHistory.filter((e) => e.type === "request");
      const requestsSortedByDate = requests.sort((a, b) => (a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0));
      const lastRequest = requestsSortedByDate[requestsSortedByDate.length - 1];
      const project = this.creditHistory.find((e) => e.request_id === lastRequest.id);

      switch (lastRequest.status) {
        case "DRAFT":
        case "REJECTED":
        case "REVIEW":
          return { request: lastRequest.status };
        case "APPROVED":
          switch (project.status) {
            case "APPROVED":
            case "FUNDED":
            case "INVESTABLE":
            case "SENT":
              return { request: lastRequest.status, project: project.status };

            default:
              return null;
          }

        default:
          return null;
      }
    }
  },
  async created() {
    if (!this.borrower_data.identity_document) this.$router.push("/");
    else await this.$store.dispatch("borrower/checkBorrowerCreditHistory", this.borrower_data.id);
  }
};
</script>
